<template>
  <div>
    <vue-headful :title="pageTitle" />
    <div class="has-text-centered has-background-primary" style="margin-bottom: 20px;">
      <h1
        class="is-size-6 has-text-white"
        style="padding: 5px 0px"
      >{{ pageheading.toLocaleUpperCase() }}</h1>
    </div>
    <div style="max-width: 100%; margin: auto; overflow: hidden;" id="body-content-area">
      <div v-if="isLoading">
        <Loading />
      </div>
      <div v-else style="overflow: hidden;">
        <div id="pagelayout">

          <div style="display: flex; flex-wrap: wrap;">
            <div v-if="!isLoading" class="top-bar control grid-button"  style="width: 20%;">
              <a class="button is-light" @click="clearFilters">
                <span>Clear Filters</span>
              </a>
            </div>
            <div class="bottom-bar" id="buttongroup" style="width: 80%; display: flex; justify-content: flex-end; padding-bottom: 1rem;">
            <div class="level">
              <div class="level-left">
                <div class="field is-grouped">
                  <div class="control">
                    <a :class="['button is-accent', !$hasPermissions(clientSession, ['INSTITUTIONS'], 2) ? 'disabled' : '']">
                      <span class="icon">
                        <i class="fal fa-plus-square"></i>
                      </span>
                      <span>
                        <router-link :to="{ name: !$hasPermissions(clientSession, ['INSTITUTIONS'], 2) ? '' : 'NewInstitution', params: {  }}"
                                     class="has-text-white">Add Institution</router-link>
                      </span>
                    </a>
                  </div>
                  <div class="control">
                    <a :class="['button is-dark', !$hasPermissions(clientSession, ['INSTITUTIONS'], 2) ? 'disabled' : '']" @click="prepDeletePrompt" :disabled="!hasSelection">
                      <span class="icon">
                        <i class="fal fa-trash-alt"></i>
                      </span>
                      <span>Delete Institution{{ hasPluralSelection ? 's' : '' }}</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            </div>
            <div :style="{maxHeight: maxHeight + 'px', height: maxHeight + 'px', width: '100%'}">
              <Grid
              v-if="maxHeight > 0"
              :style="{maxHeight: maxHeight + 'px', height: maxHeight + 'px'}"
              :filter="filter"
              :data-items="institutionItems"
              :selected-field="selectedField"
              :sortable="true"
              :sort="sort"
              :filterable="true"
              :pageable="{
                ...pageable,
                pageSizes: pageSizes
              }"
              :page-size="pageSize"
              :skip="skip"
              :take="take"
              :total="totalRecords"
              :columns="columns"
              @selectionchange="onSelectionChange"
              @headerselectionchange="onHeaderSelectionChange"
              @filterchange="filterChangeHandler"
              @sortchange="sortChangeHandler"
              @pagechange="pageChangeHandler"
            >
              <template v-slot:editTemplate="{props}">
                <td>
                  <router-link class="bcc-color" :to="{ name: 'EditInstitution', params: { id: `${props.dataItem.id}`, model: `${JSON.stringify(props.dataItem)}`, skip: `${skip}` }}">
                    {{props.dataItem.name}}
                  </router-link>
                </td>
              </template>
              <template v-slot:filterSlotTemplate="{props, methods}">
                <div class="k-filtercell">
                  <div class="k-filtercell-wrapper">
                    <input type="text" class="k-textbox" :id="`${props.field}`" :value="props.value" @input="(ev) => {methods.change({operator: 'contains', field: props.field, value: ev.target.value, syntheticEvent: ev});}">
                  </div>
                </div>
              </template>
              <template v-slot:ActionCell="{props}">
                <td style="width: 100%; padding: 0;">
                  <div class="action-cell">

                    <div v-tooltip.left-start="{ content: 'View Documents', container: false, classes: ['tooltip'] }">
                      <router-link class="link"  :to="{ name: 'InstutionsDocumentsScreen', params: { id: `${props.dataItem.id}`, model: `${JSON.stringify(props.dataItem)}` }}">
                        <i class="fa fa-file-alt"></i>
                      </router-link>
                    </div>

                    <div v-tooltip.left-start="{ content: 'Edit Institution', container: false, classes: ['tooltip'] }">
                      <router-link class="link" :to="{ name: 'EditInstitution', params: { id: `${props.dataItem.id}`, model: `${JSON.stringify(props.dataItem)}`, skip: `${skip}` }}">
                        <i class="fas fa-pencil-alt"></i>
                      </router-link>
                    </div>

                  </div>
                </td>
              </template>
            </Grid>
            </div>
          </div>

          <div id="delete-modal" class="modal is-active" v-if="showDeleteModal">
            <div class="modal-background" @click="showDeleteModal = false"></div>
            <div class="modal-card">
              <header class="modal-card-head has-bg-danger">
                <div class="modal-card-title has-bg-danger">Confirm Delete</div>
                <a class="delete" aria-label="close" @click="showDeleteModal = false"></a>
              </header>
              <section class="modal-card-body">
                <div v-if="counts">
                  <div style="margin-bottom: 10px;">By performing this action, you will delete the following users, reports, documents as well as the institution itself:<br>
                    <br>
                    <div style="width: 100%!important; padding: .5rem 0!important;" v-for="count in counts" :key="count.id">
                      <b style="text-transform: capitalize;">{{getInstitution(count.id).name}}:</b>
                      <ul>
                        <li v-if="count.response"><b>{{count.response.userCount}}</b> Users</li>
                        <li v-if="count.response"><b>{{count.response.reportCount}}</b> Reports</li>
                        <li v-if="count.response"><b>{{count.response.documentCount}}</b> Documents</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="content">
                  <br>
                  Type "<b>delete</b>" to confirm that you want to delete the institution{{ hasPluralSelection ? 's' : '' }}.
                </div>
                <div class="field">
                  <div class="control">
                    <input type="text"
                           class="input"
                           placeholder="type `delete` and then click confirm"
                           v-focus
                           v-model.trim="confirmationText"
                           @keydown.enter.prevent="deleteIfConfirmed" />
                  </div>
                </div>
              </section>
              <footer class="modal-card-foot">
                <a class="button is-danger"
                   @click="deleteIfConfirmed"
                   :disabled="confirmationText !== 'delete'">
                  <span class="icon">
                    <i class="fal fa-trash-alt"></i>
                  </span>
                  <span>Confirm</span>
                </a>
                <a class="button" @click="showDeleteModal = false; selectedRows = []">Cancel</a>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Loading from './Loading'
import { mapState } from 'vuex'
import { activeSite } from '../vuex-actions'
import { orderBy, filterBy } from '@progress/kendo-data-query'
import { Grid } from '@progress/kendo-vue-grid'

let pageName = 'Institution Management'

// https://stackoverflow.com/a/1909508
function debounce (fn, ms) {
  let timer = 0
  return function (...args) {
    clearTimeout(timer)
    timer = setTimeout(fn.bind(this, ...args), ms || 0)
  }
}

export default {
  components: {
    Loading,
    'Grid': Grid
  },
  data () {
    return {
      counts: [],
      minResults: 0,
      maxHeight: 0,
      input: {
        name: '',
        id: null,
        city: '',
        stateCode: ''
      },
      props: {
        grid: Grid,
        field: String,
        filterType: String,
        value: [String, Number, Boolean, Date],
        operator: String
      },
      statesArr: [],
      citiesArr: [],
      institutions: [],
      isLoading: true,
      showFilters: false,
      showDeleteModal: false,
      confirmationText: '',
      selectedRows: [],
      selectedRowValue: true,
      selectedField: 'selected',
      staticColumns: [
        {
          field: 'name',
          title: 'Name',
          width: '320px',
          cell: 'editTemplate',
          filterCell: 'filterSlotTemplate'
        },
        {
          field: 'id',
          title: 'ID',
          filterable: true,
          filterCell: 'filterSlotTemplate'
        },
        {
          field: 'city',
          title: 'City',
          filterable: true,
          filterCell: 'filterSlotTemplate'
        },
        {
          field: 'stateCode',
          title: 'State',
          filterable: true,
          filterCell: 'filterSlotTemplate'
        },
        {
          cell: 'ActionCell',
          width: '150px',
          filterable: false
        }
      ],
      skip: 0,
      take: 20,
      pageSize: 20,
      page: 1,
      totalRecords: 0,
      pageable: {
        buttonCount: 5,
        info: true,
        type: 'numeric',
        pageSizes: true,
        previousNext: true
      },
      payload:
      {
        filter:
        {
          institutionId: 0
        },
        limit: 20,
        offset: 0
      },
      pageheading: pageName,
      sort: [
        { field: 'id', dir: 'asc' }
      ],
      filter: {
        logic: 'and',
        filters: []
      }
    }
  },
  watch: {
    async $route (to, from) {
      let reload = await this.loadQueryParams(to.query)
      if (reload) {
        await this.goToPage(to.query.page, this.getInstitutions)
      }
    }
  },
  computed: {
    ...mapState([activeSite, 'clientSession']),
    pageTitle () {
      return pageName + ' - ' + this.activeSite.displayName
    },
    lastpage () {
      return Math.ceil(this.totalRecords / this.perPage)
    },
    selectedCity () {
      let filtered = this.filterCities
      if (filtered && filtered.length === 1) {
        return filtered[0].value
      }
      return ''
    },
    filterCities () {
      let input = this.input.city.toLowerCase()
      let res = this.citiesArr.filter(city => {
        let nameMatch = city.name.toLowerCase().includes(input)
        let valueMatch = city.value.toLowerCase().includes(input)
        return nameMatch || valueMatch
      })
      return res
    },
    selectedState () {
      let filtered = this.filterStates
      if (filtered && filtered.length === 1) {
        return filtered[0].value
      }
      return ''
    },
    filterStates () {
      let input = this.input.stateCode.toLowerCase()
      let res = this.statesArr.filter(state => {
        let nameMatch = state.name.toLowerCase().includes(input)
        let valueMatch = state.value.toLowerCase().includes(input)
        return nameMatch || valueMatch
      })
      return res
    },
    hasSelection () {
      return this.selectedRows.length > 0
    },
    hasPluralSelection () {
      return this.selectedRows.length > 1
    },
    institutionItems: function () {
      if (this.institutions.length === 0) { return [] }

      let filtered = filterBy(this.institutions, this.filter)
      return orderBy(filtered, this.sort)
    },
    areAllSelected () {
      return this.institutions.findIndex(item => item.selected === false) === -1
    },
    columns () {
      return [
        { field: 'selected', width: '28px', filterable: false, headerSelectionValue: this.areAllSelected },
        ...this.staticColumns
      ]
    },
    pageSizes () {
      let sizes = [this.minResults]
      let max = this.totalRecords
      let counter = this.minResults
      let min = this.minResults

      if (max !== 0 && min !== 0) {
        while (counter < max) {
          let size = counter + 10
          sizes.push(size > max ? max : size)
          counter += 10
        }
      }

      return sizes
    }
  },
  async created () {
    if (localStorage.institutionSkip) {
      this.skip = JSON.parse(localStorage.getItem('institutionSkip'))
      localStorage.removeItem('institutionSkip')
    }

    if (localStorage.institutionFilters) { this.filters = JSON.parse(localStorage.getItem('institutionFilters')) }

    if (localStorage.institutionSort) { this.sort = JSON.parse(localStorage.getItem('institutionSort')) }

    if (localStorage.institutionPageSize) {
      let pageSize = JSON.parse(localStorage.getItem('institutionPageSize'))
      this.take = pageSize
      this.perPage = pageSize
      this.pageSize = pageSize
    }
  },
  mounted () {
    let that = this
    this.$nextTick(() => {
      let elements = [
        '.has-text-centered.has-background-primary',
        '.top-bar',
        '.bottom-bar'
      ]

      let func = async (results) => {
        let [numberOfResults, maxHeight] = results
        this.take = numberOfResults + 2
        this.perPage = numberOfResults + 2
        this.minResults = numberOfResults + 2
        this.maxHeight = maxHeight - 50

        await this.loadQueryParams(this.$route.query)
        await this.checkPage(this.getInstitutions)
      }

      that.getListHeights('body-content-area', 50, 79, elements, func.bind(this))

      window.addEventListener('resize', () => {
        that.getListHeights('body-content-area', 50, 79, elements, func.bind(that))
      })
    })
  },
  updated () {
    // Manually repopulate filter values after server-side querying
    if (this.filters) {
      this.filters.forEach(element => {
        var filterInput = document.getElementById(element.field)
        if (filterInput) { filterInput.value = element.value }
      })
    }
  },
  methods: {
    getInstitution (institutionId) {
      let institution = null

      this.institutions.map(_institution => {
        if (institutionId === _institution.id) {
          institution = _institution
        }
      })

      return institution
    },
    async applyFilters () {
      this.goToPage(1, this.getInstitutions)
    },
    async resetFilters () {
      this.input.name = ''
      this.input.id = ''
      this.input.city = ''
      this.input.stateCode = ''
      this.$router.push({ query: { page: 1 } }).catch(err => { this.handleApiErr(err) })
      await this.checkPage(this.getInstitutions)
    },
    async refreshData () {
      // TODO: should do search conditional here so that getInstitutions can be simplified to be the same as all others
      // TODO: make generic search call that is mixin and takes payload built on getSearchPayload() convention
      await this.getInstitutions()
    },
    loadQueryParams (query) {
      let reload = false
      return reload
    },
    async getSearchQuery () {
      // set default sort
      this.payload.orderBy = 'id ASC'
      this.payload.limit = this.perPage || 20
      this.payload.offset = this.skip || 0

      if (this.filters) { this.payload.filter.gridFilters = this.filters } else { this.payload.filter.gridFilters = [] }

      if (this.sort.length) { this.payload.sort = this.sort[0].field + ' ' + this.sort[0].dir }
    },
    async getInstitutions (isInitialLoad = true) {
      try {
        if (isInitialLoad) { this.isLoading = true }

        let response = {}
        let route = `/institutions/search/`
        await this.getSearchQuery()
        response = await this.axios.post(route, this.payload)
        this.totalRecords = response.data.page.totalRecords
        this.page = response.data.page.page

        let filteredItems = filterBy(response.data.records, this.filter)
        if (filteredItems) { filteredItems = filteredItems.map(item => { return { ...item, selected: false } }) }
        this.institutions = orderBy(filteredItems, this.sort)
      } catch (error) {
        this.handleApiErr(error)
      } finally {
        this.isLoading = false
      }
    },
    deleteIfConfirmed (event) {
      if (this.confirmationText === 'delete') {
        this.deleteSelected(
          '/institutions',
          undefined,
          'Unable to delete institution(s). Please contact system administrator.',
          () => {}
        )
      }
    },
    async prepDeletePrompt () {
      if (!this.$hasPermissions(this.clientSession, ['INSTITUTIONS'], 2)) return
      this.isLoading = true
      let response = await this.api().getInstitutionCounts(this.selectedRows)
      if (response.length > 0) {
        this.counts = response
        this.confirmDelete()
      }

      this.isLoading = false
    },
    onHeaderSelectionChange (event) {
      let checked = event.event.target.checked
      Vue.set(this, 'institutions', this.institutions.map((item) => { return { ...item, selected: checked } }))

      if (checked) {
        this.selectedRows = this.institutions
          .filter(function (e) {
            return e.selected === true
          })
          .map(function (i) {
            return i.id
          })
      } else {
        this.selectedRows = []
      }
    },
    onSelectionChange (event) {
      let checked = event.event.target.checked
      const id = event.dataItem.id
      if (checked) {
        this.selectedRows.push(id)
      } else {
        var idx = this.selectedRows.indexOf(id)
        if (idx > -1) {
          this.selectedRows.splice(idx, 1)
        }
      }
      Vue.set(event.dataItem, this.selectedField, event.dataItem[this.selectedField])
    },
    pageChangeHandler: function (event) {
      this.skip = event.page.skip
      this.take = event.page.take
      this.perPage = event.page.take
      this.pageSize = event.page.take

      localStorage.setItem('institutionPageSize', event.page.take)

      this.getInstitutions(false)
    },
    updatePagerState: function (key, value) {
      const newPageableState = Object.assign({}, this.pageable, { [key]: value })
      this.pageable = newPageableState
    },
    changeHandler: function (type, value) {
      this.updatePagerState(type, value)
    },
    sortChangeHandler: function (e) {
      this.sort = e.sort
      localStorage.setItem('institutionSort', JSON.stringify(this.sort))
      this.getInstitutions(false)
    },
    change: function (filter) {
      if (filter.syntheticEvent) { this.filter = filter.syntheticEvent.filter }
    },
    filterChangeHandler: debounce(function (e) {
      if (e.filter && !this.filters) {
        this.filters = e.filter.filters
      } else if (e.filter && this.filters) {
        e.filter.filters.forEach(element => {
          this.filters.replaceOrPush(element, function (e) {
            return e.field === element.field
          })
        })
      } else {
        if (e.event.value === '') { this.filters = this.filters.filter(x => x.field !== e.event.field) }
      }

      localStorage.setItem('institutionFilters', JSON.stringify(this.filters))

      this.skip = 0
      this.getInstitutions(false)
    }, 500),
    filterRender: function (h, defaultRendering, props, change) {
      return defaultRendering
    },
    clearFilters: function () {
      // Manually clear filter values
      if (this.filters) {
        this.filters.forEach(element => {
          var filterInput = document.getElementById(element.field)
          if (filterInput) { filterInput.value = '' }
        })

        this.filters = []
      }

      this.skip = 0
      this.sort = [
        { field: 'id', dir: 'asc' }
      ]

      localStorage.removeItem('institutionFilters')
      localStorage.removeItem('institutionSort')

      this.getInstitutions(false)
    }
  }
}
</script>

<style scoped>
#body-content-area {
  position: absolute;
  top: 35px;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  overflow-y: auto;
}

#pagelayout {
  right: 100%;
  bottom: 0;
}
.k-widget k-dateinput k-state-default k-label{
  font-weight: 600 !important;
  padding: 8px;
}
.k-checkbox:checked{
  background-color:  #7fb942;
  color: white;
  border-color: #7fb942;
}
.k-pager-numbers .k-state-selected{
  background-color:  #7fb942;
  color: white;
  border-color: #7fb942;
}
.k-grid th{
  font-weight: 600 !important;
}
.k-grid td.k-state-selected, .k-grid tr.k-state-selected > td{
  background-color: #CBE3B3;
  opacity: 0.8;
  font-weight: 600;
}
.k-list .k-item.k-state-selected{
   background-color:  #7fb942;
}
.k-list .k-item.k-state-selected:hover{
  background-color: #CBE3B3;
  color: #0d0c0c
}
.k-date-picker{
  width: 90%;
  margin-right: 6px;
  border: pink;
}
.page-subtitle{
  color: #7fb942;
  font-weight: 600;
  font-size: 1em;
  margin-bottom: 8px;
}
.reportDateStatus, .status {
    margin-top: 10px;
}
.k-invalid-msg {
    display: inline-block;
}
.invalid {
    color: red;
}
.valid {
    color: green;
}
.bcc-color{
   color: #7fb942;
}
.grid-button {
  margin-bottom: 5px;
}

  .action-cell {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    font-size: 25px;
  }

  td .action-cell .link {
    display: none;
    margin: 0 .25rem;
  }

  tr:hover .action-cell .link {
    display: inline-block;
  }
  .k-grid td:first-child {
  color: transparent!important;
}

.k-grid th:first-child {
  color: transparent!important;
}

.tooltip {
    font-size: 12px!important;
    padding: 5px!important;
  }

  .k-master-row > td:first-child {
  color: transparent!important;
  display: flex;
  justify-content: center;
  align-items: center;
}

</style>
